import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { setOfficerPrefill } from 'services/actions/storeActions';
import { useAppDispatch, useSelector } from 'services/store';
import { stepRoutes } from 'constants/step-routes';
import { LandingValidation } from 'validations/LandingValidation';
import { landingPageStyles } from 'styles/landingPageStyles';
import { parseString } from 'utils/commonUtils';
import { LandingLeftContent } from './LandingLeftContent';
import { LandingRightContent } from './LandingRightContent';

export const LandingLoginPage: React.FC<{
  officer: any;
  httpReferrer?: string;
}> = ({ officer: officerDetail, httpReferrer }) => {
  const officer = useSelector((root) => root.store.officer || officerDetail);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const router = useRouter();
  const queryParams = router.query;
  const prospect = parseString(queryParams?.prospect as string);
  const invitation = parseString(queryParams?.iuuid as string);

  const formik = useFormik({
    initialValues: {
      first_name: parseString(queryParams?.first_name as string),
      last_name: parseString(queryParams?.last_name as string),
      phone_primary: parseString(queryParams?.phone_primary as string),
      state: parseString(queryParams?.state as string),
      email: parseString(queryParams?.email as string),
      confirm_email: parseString(queryParams?.email as string)
    },
    enableReinitialize: true,
    validationSchema: LandingValidation(t),
    onSubmit: (values, helper) => {
      helper.setSubmitting(true);
      dispatch(
        setOfficerPrefill({
          officer: officerDetail,
          formData: values,
          httpReferrer,
          qr: router.pathname?.includes?.('qr-apply'),
          prospectUUID: prospect,
          invitation: invitation
        })
      );
      router.push(stepRoutes.SIGN_UP.route);
      helper.setSubmitting(false);
    }
  });

  return (
    <Grid container sx={landingPageStyles.container}>
      <Grid item xs={12} sm={6} sx={landingPageStyles.leftContainer}>
        <LandingLeftContent />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={landingPageStyles.wrapper}
        alignSelf="start"
      >
        <LandingRightContent formik={formik} officer={officer} />
      </Grid>
    </Grid>
  );
};
