import { IUser } from 'services/models/IUser';
import { SignUpFormModel } from 'models/CreateAccountModel';

export interface SetOfficerPrefillAction {
  type: 'SET_OFFICER_ACTION';
  payload: {
    officer: IUser;
    formData: Partial<SignUpFormModel>;
    httpReferrer?: string;
    calculator?: boolean;
    prospectUUID?: string;
    qr?: boolean;
    invitation?: string;
  };
}
export type ClearPrefillOfficerAction = {
  type: 'CLEAR_PREFILL_OFFICER';
};

export function setOfficerPrefill(rest: {
  officer: IUser;
  formData: Partial<SignUpFormModel>;
  httpReferrer?: string;
  calculator?: boolean;
  prospectUUID?: string;
  qr?: boolean;
  invitation?: string;
}): SetOfficerPrefillAction {
  return {
    type: 'SET_OFFICER_ACTION',
    payload: {
      officer: rest.officer,
      httpReferrer: rest.httpReferrer,
      formData: rest.formData,
      calculator: rest.calculator,
      prospectUUID: rest.prospectUUID,
      qr: rest.qr,
      invitation: rest.invitation
    }
  };
}

export function clearPrefillOfficer() {
  return {
    type: 'CLEAR_PREFILL_OFFICER'
  };
}
